import { RmTextBoxProps } from "../../../../types";
import styles from "./RmTextBox.module.css";

export const RmTextBox = ({ value, onChangeCallback }: RmTextBoxProps) => {
	return (
		<>
			<span className={styles.title}>Mensaje</span>
			<textarea
				className={styles.textBox}
				value={value}
				onChange={onChangeCallback}
			></textarea>
		</>
	);
};

export default RmTextBox;
