import axios, { AxiosResponse } from "axios";
import { CookiesController } from "./cookiesController";

export class PaypalControler {
	static async getOrderId(
		productId: string,
		hasTranslation: boolean
	): Promise<string> {
		try {
			let url = process.env.REACT_APP_BACK_END_URL ?? "";
			let response: AxiosResponse = await axios.post(
				`${url}/Purchases/Order`,
				{},
				{ params: { productId, hasTranslation } }
			);

			return response.data.id ?? "";
		} catch (error) {
			console.error(`getOrderId Catching error: ${error}`);
			return "";
		}
	}

	static async captureOrder(
		productId: string,
		orderId: string,
		hasTranslation: boolean,
		clientName: string,
		clientEmail: string,
		clientLastName: string
	): Promise<boolean> {
		try {
			let url = process.env.REACT_APP_BACK_END_URL ?? "";
			let response: AxiosResponse = await axios.post(
				`${url}/Purchases/CaptureOrder`,
				{
					serviceId: parseInt(productId),
					hasTranslation,
					orderId,
					clientName,
					clientEmail,
					clientLastName,
				}
			);
			if (response.data === "COMPLETED" || response.status === 200) {
				CookiesController.sendCookies({
					serviceId: parseInt(productId),
					hasTranslation,
					orderId,
					clientName: `${clientName} ${clientLastName}`,
					clientEmail: clientEmail,
				});
				return true;
			}
			return false;

		} catch (error) {
			console.error(`Catching error: ${error}`);
			return false;
		}
	}
}
