import { createSlice } from "@reduxjs/toolkit";
import { CardsProps, PaypalConfig } from "../../types";
import { SpecialProductsEntity } from "../../entities/specialProductsEntity";

interface ConfigState {
	products: CardsProps[];
	paypalConfig: PaypalConfig;
	specialProducts: SpecialProductsEntity[];
	translationPrice: number;
}

const initialState: ConfigState = {
	products: [],
	paypalConfig: {
		clientId: "",
	},
	translationPrice: -1,
	specialProducts: []
};

export const configSlice = createSlice({
	name: "configState",
	initialState,
	reducers: {
		setProducts: (state, payload: { payload: CardsProps[]; }) => {
			state.products = payload.payload;
		},
		setPaypal: (state, payload: { payload: string; }) => {
			state.paypalConfig.clientId = payload.payload;
		},
		setTranslationPrice: (state, payload: { payload: number; }) => {
			state.translationPrice = payload.payload;
		},
		setSpecialProducts: (state, payload: { payload: SpecialProductsEntity[]; }) => {
			state.specialProducts = payload.payload;
		},

	},
});

// Action creators are generated for each case reducer function
export const { setProducts, setPaypal, setTranslationPrice, setSpecialProducts } = configSlice.actions;

export default configSlice.reducer;
