import { MenuOutlined, CloseCircleOutlined } from "@ant-design/icons";
import styles from "./HamburguerMenu.module.css";
import { useState } from "react";
import { NavBarData } from "../PresentationBlock/components/navBar/navBarData";
import { HrefBlockValues } from "../../config/hrefBlockValues";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

export default function HamburguerMenu() {
    const [isOn, setIsOn] = useState<boolean>(false);
    const dispatch = useDispatch();
    const specialProducts = useSelector((state: RootState) => state.configState.specialProducts);

    return (
        <div className={styles.mainContainer}>
            <div
                className={`${styles.tryMenuContainer} ${isOn ? styles.tryMenuExpanded : ""}`}
                onClick={() => {
                    if (!isOn) {
                        setIsOn(true);
                    }
                }}
            >
                <div className={`${styles.tryMenuOff} ${isOn ? styles.tryMenu : ""}`}>
                    {NavBarData.data.items.map((element, index) => {
                        if (element.hrefTitle !== HrefBlockValues.contactBlock) {
                            let href: string | undefined =
                                element.hrefTitle !== undefined ? `#${element.hrefTitle}` : undefined;
                            return (
                                <a
                                    key={index}
                                    href={href}
                                    onClick={(e) => {
                                        if (isOn) {
                                            setIsOn(false);
                                        }
                                        element.onClick(e, dispatch);
                                    }}
                                >
                                    {element.title}
                                </a>
                            );
                        }
                        return null;
                    })}
                    {specialProducts.length > 0 ? (
                        <a
                            href={`#${HrefBlockValues.specialProductsBlock}`}
                            className={styles.specialProductsTag}
                            onClick={() => {
                                if (isOn) {
                                    setIsOn(false);
                                }
                            }}
                        >
                            {specialProducts[0].displayName}
                        </a>
                    ) : (
                        <></>
                    )}
                </div>
                <div className={`${styles.menuIcon} ${isOn ? styles.iconOff : ""}`}>
                    <MenuOutlined />
                </div>
            </div>
            <div
                className={`${styles.closeButtonOff} ${isOn ? styles.closeButtonOn : ""}`}
                onClick={() => {
                    if (isOn) {
                        setIsOn(false);
                    }
                }}
            >
                <CloseCircleOutlined />
            </div>
        </div>
    );
}
