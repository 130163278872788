import { useEffect, useState } from "react";
import { CookiesController } from "../../../../controllers/cookiesController";
import { Cookie } from "../../../../types";
import styles from "./shoppingPopUpView.module.css";
import { ShoppingOutlined } from "@ant-design/icons";
import CartImage from "../../../../assets/images/cart_Image_ShoppingPopUp.png";

function ShoppingPopUpView(): JSX.Element {
    const [allCookies, setAllCookies] = useState<Cookie[]>([]);

    useEffect(() => {
        setAllCookies(CookiesController.getAllCookies());
    }, []);

    return (
        <div className={`${styles.mainContainer} ${allCookies.length > 0 ? "" : styles.mainContainerEmpty} `}>
            <p className="self-start text-3xl font-semibold">Mis Compras:</p>
            {allCookies.length > 0 ? (
                allCookies.map((element, index) => (
                    <div key={index} className={styles.cardContainer}>
                        <div className={styles.userInfo}>
                            <ShoppingOutlined className={styles.shoppIcon} />
                            <div className={styles.factsContainer}>
                                <span className={styles.name}>{element.cookie_value.clientName}</span>
                                <span>{element.cookie_value.clientEmail}</span>
                                <span className={styles.orderIdMobile}>{element.cookie_value.orderId}</span>
                            </div>
                        </div>
                        <span className={styles.orderIdDesktop}>{element.cookie_value.orderId}</span>
                    </div>
                ))
            ) : (
                <div className={styles.emptyMainContainer}>
                    <img
                        className="w-[150px] h-[150px] mt-4 rounded-lg"
                        src={CartImage}
                        alt="Cart for the Shopping PopUp"
                    />
                    <p className={styles.emptySalesTitle}>Por el momento, no se ha registrado ninguna compra...</p>
                </div>
            )}
        </div>
    );
}

export default ShoppingPopUpView;
