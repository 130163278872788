import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { showShoppingPopUp, showContactUsMobilePopup } from "../store/slices/PopUpState";

export class NavBarController {
    static servicesCallback(event: React.MouseEvent<HTMLAnchorElement, Event>) { };
    static testimonialsCallback(event: React.MouseEvent<HTMLAnchorElement, Event>, dispatch: Dispatch<UnknownAction>) { };
    static blogCallback(event: React.MouseEvent<HTMLAnchorElement, Event>, dispatch: Dispatch<UnknownAction>) { };
    static faqsCallback(event: React.MouseEvent<HTMLAnchorElement, Event>, dispatch: Dispatch<UnknownAction>) { };
    static contactCallback(event: React.MouseEvent<HTMLAnchorElement, Event>, dispatch: Dispatch<UnknownAction>) { };
    static contactMobileCallback(event: React.MouseEvent<HTMLAnchorElement, Event>, dispatch: Dispatch<UnknownAction>) {
        dispatch(showContactUsMobilePopup());
    };
    static shoppingCallback(event: React.MouseEvent<HTMLAnchorElement, Event>, dispatch: Dispatch<UnknownAction>) {
        dispatch(showShoppingPopUp());
    };
}
