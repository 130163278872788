import styles from "./LoaderBlock.module.css";

export const LoaderBlock = ({ smallSize = false }) => {
	if (smallSize) {
		return <div className={styles.smallLoader}></div>;
	}
	return (
		<>
			<article className={styles.mainContainer}>
				<div className={styles.loader}></div>
			</article>
		</>
	);
};
