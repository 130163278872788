import { ContactInfoCardProps } from "../../types";

export class ContactInfoBlockData {
	static cardsData: ContactInfoCardProps[] = [
		{
			cardTitle: "Mail",
			items: [
				{
					name: process.env.REACT_APP_MAIL1 ?? "",
				},
				{
					name: process.env.REACT_APP_MAIL2 ?? "",
				},
			],
		},
		{
			cardTitle: "Seguinos en Redes",
			items: [
				{
					name: process.env.REACT_APP_NETWORK1_NAME ?? "",
					url: process.env.REACT_APP_NETWORK1_URL ?? "",
				},
				{
					name: process.env.REACT_APP_NETWORK2_NAME ?? "",
					url: process.env.REACT_APP_NETWORK2_URL ?? "",
				},
				{
					name: process.env.REACT_APP_NETWORK3_NAME ?? "",
					url: process.env.REACT_APP_NETWORK3_URL ?? "",
				},
			],
		},
	];
}
