import axios, { AxiosResponse } from "axios";
import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { setPaypal, setProducts, setSpecialProducts, setTranslationPrice } from "../store/slices/ConfigState";
import { GlobalConfigEntity } from "../entities/globalConfigEntity";
import { ErrorResponse, SuccessResponse } from "../types";

export class ConfigController {
	static async getGlobalConfig(dispatch: Dispatch<UnknownAction>) {
		try {
			const response: AxiosResponse = await axios.get(
				`${process.env.REACT_APP_BACK_END_URL}/Config`
			);

			const config: GlobalConfigEntity = GlobalConfigEntity.fromJSON(
				response.data
			);
				
			dispatch(setProducts(config.products));
			dispatch(setPaypal(config.paypalConfig['clientId']));
			dispatch(setTranslationPrice(config.translationPrice));
			dispatch(setSpecialProducts(config.specialProducts));

			const successResponse: SuccessResponse = {
				status: true,
				data: config,
				config: config,
			};

			return successResponse;
		} catch (error) {
			console.error("Error fetching global config:", error);

			const errorResponse: ErrorResponse = {
				status: false,
				error: error,
			};

			return errorResponse;
		}
	}
}
