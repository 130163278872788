import { InputProps } from "../../../../types";
import styles from "./RmInput.module.css";

export const RmInput = ({
	title,
	placeHolder,
	id,
	value,
	onChangeCallback,
	type,
}: InputProps) => {
	return (
		<div className={styles.mainContainer}>
			<span className={styles.title}>{title}</span>
			<input
				id={id}
				className={styles.rmInput}
				type={type ?? 'text'}
				value={value}
				placeholder={placeHolder}
				onChange={onChangeCallback}
			/>
		</div>
	);
};

export default RmInput;
