import { useSelector } from "react-redux";
import { HrefBlockValues } from "../../config/hrefBlockValues";
import { RootState } from "../../store/store";
import RmCard from "./RmCard/RmCard";
import styles from "./ServicesBlock.module.css";

export const ServicesBlock = () => {
	const cardsData = useSelector(
		(state: RootState) => state.configState.products
	);
	const translationPrice = useSelector(
		(state: RootState) => state.configState.translationPrice
	);

	return (
		<div className={styles.mainContainer} id={HrefBlockValues.servicesBlock}>
			<span className={styles.title}>Servicios</span>
			<div className={styles.cardsContainer}>
				{cardsData.map((elem, index) => {
					return <RmCard data={elem} key={index} />;
				})}
			</div>
			<span className={styles.aditionalInfo}>
				Adicional traducción al inglés o portugués USD{translationPrice}
			</span>
		</div>
	);
};

export default ServicesBlock;
