import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { AccordionProps } from "../../../types";

export function FaqsAccordion(props: {
	data: AccordionProps[];
}): React.ReactElement {
	const items = props.data.map((elem, index) => {
		return {
			key: index,
			label: elem.itemTitle,
			children: <p className="text-[20px]">{elem.description}</p>,
		};
	});

	return (
		<div className="w-[90%] mx-auto ">
			<Collapse
				size="large"
				bordered={false}
				expandIcon={({ isActive }) => (
					<CaretRightOutlined rotate={isActive ? 270 : 90} />
				)}
				expandIconPosition="end"
				items={items}
			/>
		</div>
	);
}
