import { HrefBlockValues } from "../../../config/hrefBlockValues";
import principalImage from "../../../assets/images/Resumatio_ImagenPrincipal.jpg";
import portada from "../../../assets/images/Portada2.png";
import PresentationTexts from "../components/presentationTexts/presentationTexts";
import styles from './PresentationBlockMobile.module.css';
import { NavBarData } from "../components/navBar/navBarData";
import { useDispatch } from "react-redux";
import { wait } from "@testing-library/user-event/dist/utils";
import { NavBarItem } from "../../../types";
import { useState } from "react";

export const PresentationBlockMobile = () => {
    const dispatch = useDispatch();
    function MobileNavBarButton(props: { href: string, index: number, element: NavBarItem; }) {
        const [className, setClassname] = useState<string>('');
        return <a href={props.href} className={styles.navBarButtonComponent} key={props.index} onClick={(event) => {
            props.element.onClick(event, dispatch);
        }}>
            <div
                onTouchStart={() => {
                    setClassname(`${styles.navBarButtonTouched}`);
                }}
                onTouchEnd={async () => {
                    await wait(200);
                    setClassname('');
                }}
                className={className}>
                {props.element.title}
            </div>
        </a>;
    }

    return (
        <div className={styles.mainContainer} id={HrefBlockValues.presentationBlock}>

            <div className={styles.presentationTexts}>
                <PresentationTexts />
            </div>
            <div className={styles.principalImage}>
            </div>
            <img
                alt="portada"
                className={styles.portada}
                src={portada}
            />
        </div>
    );
};



export default PresentationBlockMobile;
