
import { useDispatch, useSelector } from 'react-redux';
import { NavBarProps } from '../../../../types';
import styles from './navBar.module.css';
import { HrefBlockValues } from '../../../../config/hrefBlockValues';
import { RootState } from '../../../../store/store';

export const NavBar = (props: { data: NavBarProps; }) => {
    const dispatch = useDispatch();
    const specialProducts = useSelector((state: RootState) => state.configState.specialProducts);

    return (
        <div className={styles.navBarContainer} >
            <div className={styles.navBar}>
                {
                    specialProducts.length > 0 ?
                    <a
                    href={`#${HrefBlockValues.specialProductsBlock}`} 
                    className={styles.specialProductsTag}
                    >
                        {specialProducts[0].displayName}
                    </a>
                    :<></>
                }
                {props.data.items.map((element, index) => {
                    if (element.hrefTitle !== HrefBlockValues.contactMobile) {
                        let href: string | undefined = element.hrefTitle !== undefined ? `#${element.hrefTitle}` : undefined;

                        return <a href={href} className={styles.navBarButtonComponent} key={index} onClick={(event) => {
                            element.onClick(event, dispatch);
                        }}>
                            <div className={styles.navBarButton}>{element.title}</div>
                            <div className={styles.navBarButtonBuddy}></div>
                        </a>;
                    }
                    return null;
                })}
                <div className={styles.navBarExtraLine}></div>
            </div>
            <div className={styles.navBarMobile}>

            </div>
        </div>
    );
};

export default NavBar;