import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import { LoaderBlock } from "./components/LoaderBlock/LoaderBlock";
import MainBlock from "./components/MainBlock/MainBlock";
import { PopUp } from "./components/PopUp/PopUp";
import { ResumatioLogo } from "./components/ResumatioLogo/ResumatioLogo";
import UpArrow from "./components/UpArrow/UpArrow";
import { ConfigController } from "./controllers/configController";
import { RootState } from "./store/store";
import HamburguerMenu from "./components/HamburguerMenu/HamburguerMenu";
import SpecialProductsMobilePopUp from "./components/SpecialProductsMobilePopUp/SpecialProductsMobilePopup";

function App() {
    const [isLoading, setIsLoading] = useState(false);
    let dispatch = useDispatch();
    const payPalState = useSelector((state: RootState) => state.configState.paypalConfig);

    const fetchGlobalConfig = useCallback(async () => {
        try {
            setIsLoading(true);
            await ConfigController.getGlobalConfig(dispatch);
        } catch (error) {
            console.error("Error fetching global config:", error);
        } finally {
            setIsLoading(false);
        }
    }, [dispatch]);

    useEffect(() => {
        fetchGlobalConfig();
    }, [fetchGlobalConfig]);

    if (isLoading) {
        return <LoaderBlock />;
    }
    return (
        <PayPalScriptProvider
            options={{
                clientId: payPalState.clientId === "" ? "test" : payPalState.clientId,
            }}
        >
            <HamburguerMenu />
            <PopUp />
            <SpecialProductsMobilePopUp />
            <MainBlock />
            <ResumatioLogo />
            <UpArrow />
        </PayPalScriptProvider>
    );
}

export default App;
