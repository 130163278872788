import Crown from "../../../assets/images/corona.png";
import { CardsProps } from "../../../types";
import check from "../../../assets/images/check.png";
import styles from "./RmCard.module.css";
import { useDispatch } from "react-redux";
import { showCheckoutPopUp } from "../../../store/slices/PopUpState";

export const RmCard = (props: { data: CardsProps }) => {
	const dispatch = useDispatch();

	return (
		<div className={styles.mainContainer}>
			{props.data.isFavourite && (
				<div className={styles.crownContainer}>
					<img className={styles.crown} src={Crown} alt={Crown} />
				</div>
			)}
			<div className={styles.containerCard}>
				<div className={styles.dataContainer}>
					<span className={styles.title}>{props.data.title}</span>
					<p className={styles.description}>{props.data.description}</p>
					<button className={styles.price}>USD {props.data.price}</button>
					<ul className={`flex flex-col items-start ${styles.pointsList}`}>
						{props.data.pointsList.map((list, index) => (
							<li className={styles.pointContainer} key={index}>
								<img className={styles.checkImg} src={check} alt="" />
								{list}
							</li>
						))}
					</ul>
				</div>
				<button
					className={`hire-card-button ${styles.hireButton}`}
					onClick={async () => {
						dispatch(showCheckoutPopUp({ productId: props.data.id }));
					}}
				>
					Contratar
				</button>
			</div>
		</div>
	);
};

export default RmCard;
