import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { wait } from "@testing-library/user-event/dist/utils";
import Carousel, { CarouselRef } from "antd/es/carousel";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { SpecialProductsEntity } from "../../../entities/specialProductsEntity";
import { showCheckoutPopUp } from "../../../store/slices/PopUpState";
import styles from "./RmSpecialCard.module.css";

export const RmSpecialCard = (props: { data: SpecialProductsEntity }) => {
    const dispatch = useDispatch();

    const ref = useRef<CarouselRef>(null);
    const [elements, setElements] = useState<Array<React.ReactElement>>();
    const [leftPressed, setLeftPressed] = useState<boolean>(false);
    const [rightPressed, setRightPressed] = useState<boolean>(false);
    const [cardsShowing, setCardsShowing] = useState<number>(0);

    useEffect(() => {
        if (props.data.sections) {
            setElements(
                props.data.sections.map((element, index) => (
                    <div className={styles.infoContainer} key={index}>
                        <div className={styles.pointContainer}>
                            <div className={styles.title}>{element.title}</div>
                        </div>
                        <span className={styles.description}>{element.description}</span>
                    </div>
                ))
            );
        }
    }, [props.data.sections]);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.containerCard}>
                <div className={styles.dataContainer}>
                    <span className={styles.displayName}>{props.data.displayName}</span>
                </div>
                <div className={styles.descriptionCarrousel}>
                    <div className={`${styles.arrowContainer} ${cardsShowing === 0 ? styles.arrowDisabled : ""} `}>
                        <LeftOutlined
                            onClick={async () => {
                                ref.current?.prev();
                            }}
                            onTouchStart={() => {
                                setLeftPressed(true);
                            }}
                            onTouchEnd={async () => {
                                await wait(200);
                                setLeftPressed(false);
                            }}
                            style={{ fontSize: "25px", padding: "10px" }}
                            className={`${styles.arrow} ${leftPressed ? styles.pressedButton : ""}`}
                        />
                    </div>
                    <div className={styles.cardsContainerMobile}>
                        <Carousel
                            autoplay={false}
                            slidesToShow={1}
                            edgeFriction={1}
                            beforeChange={(e, next) => {
                                setCardsShowing(next);
                            }}
                            arrows
                            infinite={false}
                            dots={false}
                            ref={ref}
                        >
                            {elements}
                        </Carousel>
                    </div>
                    <div
                        className={`${styles.arrowContainer} ${
                            cardsShowing === (elements?.length ?? 0) - 1 ? styles.arrowDisabled : ""
                        } `}
                    >
                        <RightOutlined
                            onClick={async () => {
                                ref.current?.next();
                            }}
                            onTouchStart={() => {
                                setRightPressed(true);
                            }}
                            onTouchEnd={async () => {
                                await wait(200);
                                setRightPressed(false);
                            }}
                            style={{ fontSize: "25px", padding: "10px" }}
                            className={`${styles.arrow} ${rightPressed ? styles.pressedButton : ""}`}
                        />
                    </div>
                </div>

                <div className={styles.descriptionDesktop}>
                    {elements?.map((element, index) => {
                        return (
                            <div className={styles.infoMainContainerDesktop} key={index}>
                                {element}
                                {index !== elements.length - 1 ? <div className={styles.plusDesktop}>+</div> : null}
                            </div>
                        );
                    })}
                </div>

                <button className={styles.price}>USD {props.data.price}</button>
                <button
                    className={`hire-card-button ${styles.hireButton}`}
                    onClick={async () => {
                        dispatch(
                            showCheckoutPopUp({
                                productId: props.data.id ?? "-1",
                            })
                        );
                    }}
                >
                    Contratar
                </button>
            </div>
        </div>
    );
};

export default RmSpecialCard;
