import { HrefBlockValues } from "../../../../config/hrefBlockValues";
import { NavBarController } from "../../../../controllers/navBarController";
import { NavBarProps } from "../../../../types";

export class NavBarData {
    static data: NavBarProps = {
        items: [
            {
                title: 'Servicios',
                onClick: NavBarController.servicesCallback,
                hrefTitle: HrefBlockValues.servicesBlock,
            },
            {
                title: 'Nosotros',
                onClick: NavBarController.servicesCallback,
                hrefTitle: HrefBlockValues.aboutUsBlock,
            },
            {
                title: 'FAQs',
                onClick: NavBarController.faqsCallback,
                hrefTitle: HrefBlockValues.faqsBlock,
            },
            {
                title: 'Contacto',
                onClick: NavBarController.contactCallback,
                hrefTitle: HrefBlockValues.contactBlock,
            },
            {
                title: 'Contacto',
                onClick: NavBarController.contactMobileCallback,
                hrefTitle: HrefBlockValues.contactMobile,
            },
            {
                title: 'Mis compras',
                onClick: NavBarController.shoppingCallback,
            },
        ]
    };
}