import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { GlobalConfigEntity } from "./entities/globalConfigEntity";

export type AccordionProps = {
	itemTitle: string;
	description: string;
};
export type FaqsBlockProps = {
	title: string;
	items: AccordionProps[];
};

export type CardsProps = {
	id: string;
	title: string;
	description: string;
	isFavourite?: boolean;
	price: number;
	pointsList: string[];
};
export type ProductsJson = {
	id?: string;
	name?: string;
	description?: string;
	isFavourite?: boolean;
	price?: number;
	pointsList?: string[];
};

export type SpecialProductsJson = {

	displayName?: string,
	isService?: boolean,
	name?: string,
	id?: string;
	description?: string;
	isFavourite?: boolean;
	price?: number;
	sections?: SectionJson[];
};

export type SectionJson = {
	description?: string,
	order?: number,
	title?: string,
};

export type AboutUsBlockProps = {
	title: string;
	info: string;
};

export type HyperVinculeTag = {
	name: string;
	url?: string;
};

export type ContactInfoCardProps = {
	cardTitle: string;
	items: HyperVinculeTag[];
};

export type ContactInfoBlockProps = {
	items: ContactInfoCardProps[];
};

export type NavBarItem = {
	title: string;
	hrefTitle?: string;
	onClick: (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		dispatch: Dispatch<UnknownAction>
	) => void;
};
export type NavBarProps = {
	items: NavBarItem[];
};

export type InputProps = {
	title: string;
	placeHolder?: string;
	id: string;
	type?: React.HTMLInputTypeAttribute;
	value?: string | undefined;
	onChangeCallback?: React.ChangeEventHandler<HTMLInputElement>;
};

export type RmTextBoxProps = {
	value?: string | undefined;
	onChangeCallback?: React.ChangeEventHandler<HTMLTextAreaElement>;
};

export type Cookie = {
	id: number;
	cookie_value: CookieValue;
};

export type CookieValue = {
	serviceId: number;
	hasTranslation: boolean;
	orderId: string;
	clientName: string;
	clientEmail: string;
};

export enum PopupType {
	checkout,
	shopping,
	mobile,
	contactUsMobile,
}

export type PaypalConfig = {
	clientId: string;
};

export type SuccessResponse = {
	status: boolean;
	data: any;
	config: GlobalConfigEntity;
};

export type ErrorResponse = {
	status: boolean;
	error: any;
};
