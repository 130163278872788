import { PaypalConfig } from "../types";

export class PaypalConfigEntity {
	clientId: string;

	constructor(clientId: string) {
		this.clientId = clientId;
	}

	static fromJSON(json?: PaypalConfig): PaypalConfigEntity {
		if (json !== undefined) {
			return new PaypalConfigEntity(json.clientId.toString());
		} else {
			return new PaypalConfigEntity("");
		}
	}
}
