import { ProductsJson } from "../types";

export class ProductEntity {
	id: string;
	title: string;
	description: string;
	isFavourite?: boolean;
	price: number;
	pointsList: string[];

	constructor(json: ProductsJson) {
		this.id = json.id ?? '';
		this.title = json.name ?? '';
		this.description = json.description ?? '';
		this.isFavourite = json.isFavourite ?? false;
		this.price = json.price ?? 0;
		this.pointsList = json.pointsList ?? [];
	}

	static fromJSON(products?: ProductsJson): ProductEntity {
		if (products !== undefined) {
			return new ProductEntity(products ?? {});
		} else {
			return new ProductEntity({});
		}
	}
}
