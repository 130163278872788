import { SpecialProductsJson } from "../types";
import { SectionEntity } from "./sectionEntity";

export class SpecialProductsEntity {
	displayName: string;
	isService: boolean;
	name: string;
	id?: string;
	isFavourite?: boolean;
	price?: number;
	sections?: SectionEntity[];

	constructor(json: SpecialProductsJson) {
		this.id = json.id ?? '';
		this.name = json.name ?? '';
		this.isFavourite = json.isFavourite ?? false;
		this.price = json.price ?? 0;
		this.displayName = json.displayName ?? '';
		this.isService = json.isService ?? false;
		this.sections = SectionEntity.fromJSONList(json.sections ?? [])
	}

	static fromJSON(products?: SpecialProductsJson): SpecialProductsEntity {
		if (products !== undefined) {
			return new SpecialProductsEntity(products ?? {});
		} else {
			return new SpecialProductsEntity({});
		}
	}
}
