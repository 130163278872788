import { HrefBlockValues } from "../../config/hrefBlockValues";
import { AboutUsBlockProps } from "../../types";
import styles from "./AboutUsBlock.module.css";

export const AboutusBlock = (props: { data: AboutUsBlockProps }) => {
    return (
        <div className={styles.background} id={HrefBlockValues.aboutUsBlock}>
            <span className={styles.titleMobile}>{props.data.title}</span>
            <div className={styles.mainContainer}>
                <span className={styles.title}>{props.data.title}</span>
                <span className={styles.info}>{props.data.info}</span>
            </div>
        </div>
    );
};

export default AboutusBlock;
