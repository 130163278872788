import { HrefBlockValues } from "../../config/hrefBlockValues";
import styles from "./PresentationBlock.module.css";
import NavBar from "./components/navBar/navBar";
import { NavBarData } from "./components/navBar/navBarData";
import PresentationTexts from "./components/presentationTexts/presentationTexts";
import portada from "../../assets/images/Resumatio_ImagenPrincipal.jpg";

export const PresentationBlock = () => {
	return (
		<div
			className={styles.mainContainer}
			id={HrefBlockValues.presentationBlock}
		>
			<NavBar data={NavBarData.data} />
			<img
				className="absolute -z-10 top-0 h-full w-full"
				src={portada}
				alt="resumatio background"
			/>
			<PresentationTexts />
		</div>
	);
};

export default PresentationBlock;
