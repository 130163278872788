import { PaypalConfig, ProductsJson, SpecialProductsJson } from "../types";
import { PaypalConfigEntity } from "./paypalConfigEntity";
import { ProductEntity } from "./productEntity";
import { SpecialProductsEntity } from "./specialProductsEntity";

export class GlobalConfigEntity {

    paypalConfig: PaypalConfigEntity;
    products: ProductEntity[];
    specialProducts: SpecialProductsEntity[];
    translationPrice: number;

    constructor(paypalConfig: PaypalConfigEntity, products: ProductEntity[], specialProducts: SpecialProductsEntity[], translationPrice: number) {
        this.paypalConfig = paypalConfig;
        this.products = products;
        this.specialProducts = specialProducts;
        this.translationPrice = translationPrice;
    }

    static fromJSON(json: { paypalConfig?: PaypalConfig, products?: ProductsJson[], specialProducts? : SpecialProductsJson[], translationPrice?: number; }): GlobalConfigEntity {
        let realProducts: ProductEntity[] = [];
        let specialProducts: SpecialProductsEntity[] = [];
        
        if (json.products) {
            json.products.forEach((productJson) => {
                realProducts.push(ProductEntity.fromJSON(productJson));
            });
        }
        if (json.specialProducts) {
            json.specialProducts.forEach((specialProduct) => {
                specialProducts.push(SpecialProductsEntity.fromJSON(specialProduct));
            });
        }

        return new GlobalConfigEntity(
            PaypalConfigEntity.fromJSON(json.paypalConfig),
            realProducts,
            specialProducts,
            json.translationPrice ?? 0,
        );
    }
}