import { useSelector } from "react-redux";
import { HrefBlockValues } from "../../config/hrefBlockValues";
import { RootState } from "../../store/store";
import styles from "./SpecialProductsBlock.module.css";
import RmSpecialCard from "./RmSpecialCard/RmSpecialCard";

export const SpecialProductsBlock = () => {
    const specialProducts = useSelector((state: RootState) => state.configState.specialProducts);

    if (specialProducts.length === 0) {
        return <div>cargando</div>;
    }
    return (
        <div className={styles.mainContainer} id={HrefBlockValues.specialProductsBlock}>
            <div className={styles.dataContainer}>
                <span className={styles.displayName}>{specialProducts[0].displayName}</span>
            </div>
            {specialProducts.length > 0 && <RmSpecialCard data={specialProducts[0]} />}
        </div>
    );
};

export default SpecialProductsBlock;
