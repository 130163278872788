import { GeneralInfoDataModel } from "../../GeneralInfoData";
import styles from "./dataCard.module.css";

export const DataCard = (props: { data: GeneralInfoDataModel }) => {
	return (
		<div className={styles.mainContainer}>
			<img className={styles.icon} src={props.data.image} alt="" />
			<span className={styles.title}>{props.data.title}</span>
			<span className={styles.subTitle}>{props.data.subTitle}</span>
		</div>
	);
};

export default DataCard;
