import { useState } from "react";
import styles from "./contactUsMobileView.module.css";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { EmailController } from "../../../../controllers/emailController";
import UiController from "../../../../controllers/uiController";
import { wait } from "@testing-library/user-event/dist/utils";
import { HrefBlockValues } from "../../../../config/hrefBlockValues";
import RmInput from "../../../ContactUsBlock/components/RmInput/RmInput";
import RmTextBox from "../../../ContactUsBlock/components/RmTextBox/RmTextBox";
import { LoaderBlock } from "../../../LoaderBlock/LoaderBlock";

function ContactUsMobileView(): JSX.Element {


	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const [name, setName] = useState<string>("");
	const [message, setMessage] = useState<string>("");
	const [succesMessage, setSuccesMessage] = useState<boolean | null>(null);

	async function sendContactEmail() {
		setIsLoading(true);
		let emailStatus = await EmailController.sendEmail(email, name, message);

		setIsLoading(false);

		// Reiniciar los valores de los inputs a vacíos
		setEmail("");
		setName("");
		setMessage("");

		// Shows needed message, succes or error
		setSuccesMessage(emailStatus);
		await wait(3000);
		setSuccesMessage(null);
	}
	const isButtonDisabled = () => {
		return !UiController.isEmailValid(email) || name === "" || message === "";
	};
	return (
		<div className={styles.mainContainer} id={HrefBlockValues.contactBlock}>
			<span className={styles.title}>Contáctanos</span>
			<div className={styles.formsContainer}>
				<RmInput
					id="contactNameInput"
					title="Nombre"
					placeHolder="Nombre"
					value={name}
					onChangeCallback={(e) => setName(e.target.value)}
				/>
				<RmInput
					id="contactEmailInput"
					title="Email"
					placeHolder="Email"
					value={email}
					onChangeCallback={(e) => setEmail(e.target.value)}
				/>
				<RmTextBox
					value={message}
					onChangeCallback={(e) => setMessage(e.target.value)}
				/>
			</div>
			{succesMessage === true ? (
				<p className={styles.successMessage}>
					<span>Email enviado correctamente</span>
					<CheckCircleOutlined className={styles.succesIcon} />
				</p>
			) : succesMessage === false ? (
				<p className={styles.errorMessage}>
					<span>
						Ha ocurrido un error, por favor vuelva a intentarlo más tarde
					</span>
					<CloseCircleOutlined className={styles.succesIcon} />
				</p>
			) : (
				<button
					className={
						isButtonDisabled() ? styles.buttonIsDisabled : styles.sendButton
					}
					onClick={sendContactEmail}
					disabled={isButtonDisabled()}
				>
					{isLoading ? <LoaderBlock smallSize /> : "Enviar"}
				</button>
			)}
		</div>
	);
}

export default ContactUsMobileView;
