import { AboutUsBlockProps } from "../../types";

export class AboutUsBlockData {
	static data: AboutUsBlockProps = {
		title: "Nosotros",
		info: `En Resumatio, creamos currículums / hoja de vida que trascienden y son la clave para destacar entre la 
        competencia. Construimos historias profesionales impactantes, resaltando tus habilidades y experiencias de 
        manera única. Nuestro objetivo es abrirte las puertas hacia nuevas oportunidades laborales. ¡Descubre cómo tu 
        currículum / hoja de vida puede ser tu mejor carta de presentación para tu futuro éxito laboral!`,
	};
}
