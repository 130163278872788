import { CloseOutlined } from "@ant-design/icons";
import { wait } from "@testing-library/user-event/dist/utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import styles from "./SpecialProductsMobilePopup.module.css";

export default function SpecialProductsMobilePopUp(): JSX.Element {
    const specialProducts = useSelector((state: RootState) => state.configState.specialProducts);
    const [visible, setVisible] = useState<boolean>(false);
    const [display, setDisplay] = useState<boolean>(true);

    async function onClickOpacity() {
        setVisible(false);
        await wait(500);
        setDisplay(false);
    }

    useEffect(() => {
        wait(300).then(() => {
            setVisible(true);
        });
    }, []);

    useEffect(() => {
        if (window.innerWidth < 720) {
            if (visible) {
                document.getElementsByTagName("body")[0].className = `${styles.notScrolling}`;
            } else {
                document.getElementsByTagName("body")[0].className = `${styles.scrolling}`;
            }
        }
    }, [visible]);

    return (
        <div className={styles.mainContainer} style={{ display: display ? "" : "none" }}>
            <div
                className={styles.backGround}
                style={{ opacity: visible ? "" : 0, display: display ? "" : "none" }}
            ></div>
            <div className={styles.popUpContainer} style={{ opacity: visible ? "" : 0 }}>
                <div className={styles.closeButton} onClick={onClickOpacity}>
                    <CloseOutlined />
                </div>
                <div className={styles.portadaImg}></div>
                <div className={styles.info}>
                    {
                        <span className={styles.title}>
                            {specialProducts.length > 0 && specialProducts[0].displayName}
                        </span>
                    }
                    {specialProducts.length > 0 &&
                        specialProducts[0].sections?.map((elem, index) => {
                            return (
                                <div className={styles.infoTagContainer} key={index}>
                                    <span className={styles.infoTag}> {elem.title} </span>
                                    {index < (specialProducts[0].sections?.length ?? 0) - 1 && (
                                        <span className={styles.plus}>+</span>
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
