import { SectionJson } from "../types";

export class SectionEntity {
	description: string;
	order: number;
	title: string;

	constructor(json: SectionJson) {
		this.title = json.title ?? '';
		this.description = json.description ?? '';
		this.order = json.order ?? 0;
	}

	static fromJSON(section?: SectionJson): SectionEntity {
		if (section) {
			return new SectionEntity(section);
		} else {
			return new SectionEntity({});
		}
	}
	static fromJSONList(sections?: SectionJson[]): SectionEntity[] {
		if (sections) {
			return sections.map(section => {
				if (section) {
					return new SectionEntity(section);
				} else {
					return new SectionEntity({});
				}
				
			});
		}
		else return [];
	}

}
