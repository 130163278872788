import { useDispatch, useSelector } from 'react-redux';
import UiController from '../../../controllers/uiController';
import { ContactInfoCardProps } from '../../../types';
import styles from './ContactInfoCard.module.css';
import { RootState } from '../../../store/store';

export const ContactInfoCard = (props: { data: ContactInfoCardProps; }) => {
    const dispatch = useDispatch();
    const clipboardState = useSelector((state: RootState) => state.clipboardState);

    return (
        <div className={styles.mainContainer}>
            <span className={styles.title}>{props.data.cardTitle}</span>
            {props.data.items.map((elem, index) => {
                return <a
                    id={`${elem.url} + ${index}`}
                    target='_blank'
                    rel='noreferrer'
                    key={index}
                    href={elem.url}
                    className={styles.hyperVincule}
                    onClick={async e => {
                        let copyText = document.getElementById(`${elem.url} + ${index}`);
                        if (elem.url === undefined) {
                            e.preventDefault();
                            if (!clipboardState.visible) {
                                await UiController.copyToClipboard(elem.name, dispatch, e.clientX, e.clientY, copyText!);
                            }
                        }
                    }}>
                    {elem.name}
                </a>;
            })}
        </div>
    );
};

export default ContactInfoCard;