import Cookies from "js-cookie";
import { Cookie, CookieValue } from "../types";


export class CookiesController {
	static sendCookies = (value: CookieValue) => {
		const newCookie: Cookie = {
			id: Math.ceil(Math.random() * 1000),
			cookie_value: value,
		};
		const allCookies = CookiesController.getAllCookies();
		const updatedCookies = [...allCookies, newCookie];
		CookiesController.setAllCookies(updatedCookies);
	};

	static deleteCookie = (id: number) => {
		const allCookies = CookiesController.getAllCookies();
		const updatedCookies = allCookies.filter((cookie) => cookie.id !== id);
		CookiesController.setAllCookies(updatedCookies);
	};

	static getAllCookies = (): Cookie[] => {
		const cookiesArray = Cookies.get("cookies");
		return cookiesArray ? JSON.parse(cookiesArray) : [];
	};

	static setAllCookies = (cookies: Cookie[]) => {
		Cookies.set("cookies", JSON.stringify(cookies));
	};
}



