import { createSlice } from "@reduxjs/toolkit";

interface ClipboardState {
	visible: boolean;
	mouseX: number;
	mouseY: number;

}

const initialState: ClipboardState = {
	visible: false,
	mouseX: 0,
	mouseY: 0,
};

export const clipboardSlice = createSlice({
	name: "clipboardState",
	initialState,
	reducers: {
		setVisible: (state, payload: { payload: boolean; }) => {
			state.visible = payload.payload;
		},
		setMouseX: (state, payload: { payload: number; }) => {
			state.mouseX = payload.payload;
		},
		setMouseY: (state, payload: { payload: number; }) => {
			state.mouseY = payload.payload;
		},


	},
});

// Action creators are generated for each case reducer function
export const { setVisible, setMouseX, setMouseY } = clipboardSlice.actions;

export default clipboardSlice.reducer;
