export class HrefBlockValues {
    static aboutUsBlock = "AboutUs";
    static contactInfoBlock = "ContactInfo";
    static contactBlock = "Contact";
    static contactMobile = "ContactMobile";
    static faqsBlock = "Faqs";
    static generalInfoBlock = "GeneralInfo";
    static presentationBlock = "Presentation";
    static servicesBlock = "Services";
    static specialProductsBlock = "SpecialServices";
}
