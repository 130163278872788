import { HrefBlockValues } from '../../config/hrefBlockValues';
import { FaqsBlockProps } from '../../types';
import { FaqsAccordion } from './FaqsAccordion/FaqsAccordion';
import styles from './FaqsBlock.module.css';

export const FaqsBlock = (props: { data: FaqsBlockProps; }) => {

    return (
        <div className={styles.mainContainer} id={HrefBlockValues.faqsBlock}>
            <span className={styles.title}>FAQs</span>
            <FaqsAccordion data={props.data.items} />
        </div>
    );
};

export default FaqsBlock;