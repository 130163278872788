import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import styles from "./PopUp.module.css";
import { closePopUp } from "../../store/slices/PopUpState";
import { PopupType } from "../../types";
import { useCallback, useEffect, useState } from "react";
import { CheckoutPopUpView } from "./components/checkoutPopUpView/checkoutPopUpView";
import ShoppingPopUpView from "./components/shoppingPopUpView/shoppingPopUpView";
import MobilePopUpView from "./components/mobilePopUpView/mobilePopUpView";
import { wait } from "@testing-library/user-event/dist/utils";
import ContactUsMobileView from "./components/contactUsMobileView/contactUsMobileView";
import { CloseOutlined } from "@ant-design/icons";

export function PopUp() {
    const mainContainerStyle: React.CSSProperties = {};
    const dispatch = useDispatch();
    const minWidthResolution = 720;

    const popUpState = useSelector((state: RootState) => state.popUpstate);
    let [view, setView] = useState<JSX.Element | null>(null);

    const changePopUpView = useCallback(() => {
        if (popUpState.type === PopupType.checkout) {
            setView(<CheckoutPopUpView />);
        } else if (popUpState.type === PopupType.shopping) {
            setView(<ShoppingPopUpView />);
        } else if (popUpState.type === PopupType.mobile) {
            setView(<MobilePopUpView />);
        } else if (popUpState.type === PopupType.contactUsMobile) {
            setView(<ContactUsMobileView />);
        }
    }, [popUpState.type]);

    changePopUpStyle();
    useEffect(() => {
        changePopUpView();
    }, [changePopUpView, popUpState.type]);

    return (
        <div className={`${styles.mainContainer} ${styles.transition}`} style={mainContainerStyle}>
            <div
                className={`${styles.backGround} ${styles.transition}`}
                onClick={() => {
                    if (popUpState.type !== PopupType.mobile) {
                        dispatch(closePopUp());
                        wait(500).then(() => {
                            setView(null);
                        });
                    }
                }}
            />
            <div
                className={
                    window.innerWidth < minWidthResolution
                        ? `${styles.popUpMobile} ${styles.transition} ${
                              popUpState.type === PopupType.contactUsMobile ? styles.backGroundResumatio : ""
                          }`
                        : `${styles.popUp} ${styles.transition} `
                }
            >
                {view ?? view}
                {window.innerWidth < minWidthResolution && popUpState.type === PopupType.mobile && (
                    <button
                        className="block self-center h-10 rounded-lg w-full bg-principalOrange text-lg text-principalBlack font-semibold"
                        onClick={async () => {
                            dispatch(closePopUp());
                            wait(500).then(() => {
                                setView(null);
                            });
                        }}
                    >
                        Continuar
                    </button>
                )}
            </div>
            <div
                className={styles.closePopupX}
                onClick={() => {
                    dispatch(closePopUp());
                }}
            >
                <CloseOutlined />
            </div>
        </div>
    );

    function changePopUpStyle() {
        if (popUpState.visible) {
            mainContainerStyle.opacity = "1";
            mainContainerStyle.visibility = "visible";
            mainContainerStyle.bottom = "0px";
            document.getElementsByTagName("body")[0].className = `${styles.notScrolling} ${styles.transition}`;
        } else {
            mainContainerStyle.opacity = "0";
            mainContainerStyle.bottom = "100vh";
            mainContainerStyle.visibility = "hidden";
            document.onscroll = null;
            document.getElementsByTagName("body")[0].className = `${styles.scrolling} ${styles.transition}`;
        }
    }
}
