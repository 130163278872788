import styles from "./presentationTexts.module.css";
export const PresentationTexts = () => {
	return (
		<div className={styles.textsContainer}>
			<span className={styles.title}>
				Deja que tu CV / Hoja de Vida hable por ti
			</span>
			<span className={styles.subTitle}>
				Nuestra especialidad es convertir tu experiencia y conocimientos en
				oportunidades
			</span>
		</div>
	);
};

export default PresentationTexts;
