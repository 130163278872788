import { useState } from 'react';
import upArrowImage from '../../assets/images/whatsAppLogo.png';
import styles from './UpArrow.module.css';

export default function UpArrow() {

    const [visible, setVisible] = useState<boolean>(false);
    document.addEventListener('scroll', (event) => {
        if (window.scrollY > 100) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    });


    return <a rel='noreferrer' href={process.env.REACT_APP_WHATSAPP_LINK} className={`${styles.mainContainer} ${visible ? styles.visible : styles.invisible}`} target='_blank'>
        <img alt='upArrowImage' src={upArrowImage} />
    </a>;
}