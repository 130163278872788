import { FaqsBlockProps } from "../../types";

export class FaqsBlockData {
	static data: FaqsBlockProps = {
		title: "FAQs",
		items: [
			{
				itemTitle: "¿Por qué elegir a Resumatio?",
				description:
					"Elige nuestra empresa para una experiencia personalizada y creativa. Nos destacamos por la calidad, resultados probados y atención cercana a tus necesidades. Estamos actualizados con las últimas tendencias para impulsar tu éxito laboral.",
			},
			{
				itemTitle: "¿Qué es el ATS?",
				description:
					"El ATS (Sistema de Seguimiento de Solicitantes) es un software usado por empresas para gestionar aplicaciones de empleo. Facilita la organización, almacenamiento y seguimiento de currículums, agilizando la búsqueda de candidatos, las entrevistas y el proceso de contratación. Es esencial para una gestión eficiente del flujo de candidatos y la selección del talento adecuado.",
			},
			{
				itemTitle:
					"¿Cuál es su tiempo de entrega habitual para un currículum terminado?",
				description:
					"Nuestro tiempo de entrega habitual varía según la complejidad del currículum y nuestra carga de trabajo actual. Sin embargo, nos esforzamos por entregar currículums de alta calidad en un plazo razonable, generalmente dentro de una semana o menos.",
			},
			{
				itemTitle: "¿Puedo optar por revisiones gratuitas?",
				description:
					"Nuestro compromiso es ofrecer currículums de calidad excepcional. Si sientes que tu currículum puede mejorarse, cuentas con revisiones ilimitadas sin costo. Nos esforzamos por garantizar que tu currículum se ajuste a todas tus expectativas.",
			},
			{
				itemTitle:
					"¿Qué información necesitan de mi parte para comenzar el proceso?",
				description:
					"Necesitamos información detallada sobre tu historial laboral, educación, habilidades y logros. También es útil proporcionar descripciones de trabajo anteriores o cualquier otra información relevante que desees incluir en tu currículum.",
			},
		],
	};
}
