
import { HrefBlockValues } from '../../config/hrefBlockValues';
import { ContactInfoCardProps } from '../../types';
import styles from './ContactInfoBlock.module.css';
import ContactInfoCard from './ContactInfoCard/ContactInfoCard';

export const ContactInfoBlock = (props: { data: ContactInfoCardProps[]; }) => {

    return (
        <div className={styles.mainContainer} id={HrefBlockValues.contactInfoBlock}>
            {props.data.map((elem, index) => {
                return <ContactInfoCard key={index} data={elem} />;
            })}
        </div>
    );
};

export default ContactInfoBlock;