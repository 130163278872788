import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { setMouseX, setMouseY, setVisible } from "../store/slices/ClipboardState";


export default class UiController {
    static async copyToClipboard(elem: string, dispatch: Dispatch<UnknownAction>, xPosition: number, yPosition: number, elementById: HTMLElement): Promise<void> {

        try {
            await navigator.clipboard.writeText(elem);
        } catch (error) {
            let text = elem;
            var textField = document.createElement('textarea');
            textField.innerText = text;
            document.body.appendChild(textField);
            textField.select();
            textField.setSelectionRange(0, 99999);
            document.execCommand('copy');
            textField.remove();
        }

        dispatch(setVisible(true));
        dispatch(setMouseX(xPosition));
        dispatch(setMouseY(yPosition));
    }

    static isEmailValid(email: string): boolean {
        const regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }
}