import styles from "./ResumatioLogo.module.css";
import resumatioLogo from "../../assets/images/Resumatiologo.png";

export const ResumatioLogo = () => {
	return (
		<div
			className={styles.mainContainer}
			onClick={() => {
				window.scroll({ top: 0, left: 0 });
			}}
		>
			<img src={resumatioLogo} className={styles.logo} alt="Resumatio Logo" />
		</div>
	);
};
