import { createSlice } from "@reduxjs/toolkit";
import { PopupType } from "../../types";

interface PopUpState {
    visible: boolean;
    type: PopupType | null;
    selectedProductId: string | null;
}

const initialState: PopUpState = {
    visible: false,
    type: null,
    selectedProductId: "",
};

export const popUpSlice = createSlice({
    name: "popUpState",
    initialState,
    reducers: {
        showShoppingPopUp: (state) => {
            state.type = PopupType.shopping;
            state.visible = true;
        },
        showContactUsMobilePopup: (state) => {
            state.type = PopupType.contactUsMobile;
            state.visible = true;
        },
        showMobilePopUp: (state) => {
            state.type = PopupType.mobile;
            state.visible = true;
        },
        showCheckoutPopUp: (state, payload: { payload: { productId: string } }) => {
            state.type = PopupType.checkout;
            state.visible = true;
            state.selectedProductId = payload.payload.productId;
        },
        closePopUp: (state) => {
            state.visible = false;
            state.type = null;
            state.selectedProductId = null;
        },
    },
});

// Action creators are generated for each case reducer function
export const { showCheckoutPopUp, showShoppingPopUp, showContactUsMobilePopup, closePopUp, showMobilePopUp } =
    popUpSlice.actions;

export default popUpSlice.reducer;
