import { useDispatch, useSelector } from 'react-redux';
import styles from './CoppiedToClipboard.module.css';
import { RootState } from '../../store/store';
import { useEffect, useState } from 'react';
import { wait } from '@testing-library/user-event/dist/utils';
import { setVisible } from '../../store/slices/ClipboardState';


export default function CoppiedToClipboard() {

    const clipboardState = useSelector((state: RootState) => state.clipboardState);
    const dispatch = useDispatch();
    let [style, setStyle] = useState<React.CSSProperties>({ opacity: 0 });


    async function changeStyles() {
        setStyle({ opacity: 0, top: clipboardState.mouseY - 35, left: clipboardState.mouseX - 150 });
        await wait(300);
        setStyle({ opacity: 1, top: clipboardState.mouseY - 35, left: clipboardState.mouseX - 150 });
        await wait(1500);
        setStyle({ opacity: 0, top: clipboardState.mouseY - 35, left: clipboardState.mouseX - 150 });
        dispatch(setVisible(false));
    }

    useEffect(() => {
        if (clipboardState.visible === true) {
            changeStyles();
        }
    }, [clipboardState]);

    return <div className={styles.mainContainer} style={style}>
        Copiado al portapapeles
    </div>;

}