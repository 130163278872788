import axios, { AxiosResponse } from "axios";

export class EmailController {
	static async sendEmail(
		clientEmail: string,
		clientName: string,
		issue: string
	): Promise<boolean> {
		try {
			let response: AxiosResponse = await axios.post(
				`${process.env.REACT_APP_BACK_END_URL}/Emails/ContactEmail`,
				{
					clientEmail: clientEmail,
					clientName: clientName,
					issue: issue,
				}
			);

			if (response.status >= 200) {
				return true;
			}
			return false;
		} catch (error) {
			console.error(error);
			return false;
		}
	}
}
