import React, { useEffect, useRef, useState } from "react";
import { HrefBlockValues } from "../../config/hrefBlockValues";
import DataCard from "./components/dataCard/dataCard";
import styles from "./GeneralInfo.module.css";
import { GeneralInfoData } from "./GeneralInfoData";
import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { CarouselRef } from "antd/es/carousel";
import { wait } from "@testing-library/user-event/dist/utils";

export const GeneralInfoBlock = () => {
	const ref = useRef<CarouselRef>(null);

	const [elements, setElements] = useState<Array<React.ReactElement>>();
	const [leftPressed, setLeftPressed] = useState<boolean>(false);
	const [rightPressed, setRightPressed] = useState<boolean>(false);


	useEffect(() => {
		setElements(GeneralInfoData.data.map((element, index) => (
			<DataCard key={index} data={element} />
		)));
	}, []);

	return (
		<div className={styles.mainContainer} id={HrefBlockValues.generalInfoBlock}>
			<span className={styles.title}>
				Explora el funcionamiento detrás de cada paso hacia el éxito
			</span>
			<div className={styles.cardsContainer}>
				{GeneralInfoData.data.map((element, index) => (
					<DataCard key={index} data={element} />
				))}
			</div>
			<div className={styles.mobileCarousel}>
				<div className={styles.arrowContainer}>
					<LeftOutlined onClick={async () => {
						ref.current?.prev();
					}}
						onTouchStart={() => {
							setLeftPressed(true);
						}}
						onTouchEnd={async () => {
							await wait(200);
							setLeftPressed(false);
						}}
						style={{ fontSize: '50px' }} className={`${styles.arrow} ${leftPressed ? styles.pressedButton : ''}`} />

				</div>
				<div className={styles.cardsContainerMobile}>
					<Carousel
						autoplay={false}
						slidesToShow={2}
						centerPadding="true"
						edgeFriction={1}
						arrows
						dots={false}
						ref={ref}
					>
						{elements}
					</Carousel>
				</div>
				<div className={styles.arrowContainer}>

					<RightOutlined
						onClick={async () => {
							ref.current?.next();
						}}
						onTouchStart={() => {
							setRightPressed(true);
						}}
						onTouchEnd={async () => {
							await wait(200);
							setRightPressed(false);
						}}
						style={{ fontSize: '50px' }} className={`${styles.arrow} ${rightPressed ? styles.pressedButton : ''}`} />
				</div>
			</div>
		</div>
	);
};

export default GeneralInfoBlock;
