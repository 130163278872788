import { PayPalButtons } from "@paypal/react-paypal-js";
import RmInput from "../../../ContactUsBlock/components/RmInput/RmInput";
import styles from "./checkoutPopUpView.module.css";
import { useEffect, useRef, useState, useCallback } from "react";
import { RootState } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { PaypalControler } from "../../../../controllers/paypalController";
import { LoaderBlock } from "../../../LoaderBlock/LoaderBlock";
import { closePopUp } from "../../../../store/slices/PopUpState";
import UiController from "../../../../controllers/uiController";
import { wait } from "@testing-library/user-event/dist/utils";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import ResumatioPopUpLogo from "../../../../assets/images/ResumatioPopUpLogo.png";

export function CheckoutPopUpView(): JSX.Element {
    const [success, setSucces] = useState<boolean>(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const translationPrice = useSelector((state: RootState) => state.configState.translationPrice);

    const [lastName, setLastName] = useState<string>("");
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [paypalEnlabed, setPaypalEnlabed] = useState<boolean>(false);
    const [orderId, setOrderId] = useState<string>("");
    const popUpState = useSelector((state: RootState) => state.popUpstate);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCapturing, setIscapturing] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<boolean>(false);
    const [alreadyCaptured, setAlreadyCaptured] = useState<boolean>(false);
    const isCheckedRef = useRef(isChecked);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsEmailValid(UiController.isEmailValid(email));

        if (name.length > 0 && email.length > 0 && lastName.length > 0 && isEmailValid) {
            setPaypalEnlabed(true);
        } else {
            setPaypalEnlabed(false);
        }
    }, [name, email, lastName, isEmailValid]);

    const onApprove = useCallback(async () => {
        const includeTranslation = isCheckedRef.current;
        setIscapturing(true);

        if (!alreadyCaptured) {
            let isSucces = await PaypalControler.captureOrder(
                popUpState.selectedProductId ?? "",
                orderId,
                includeTranslation,
                name,
                email,
                lastName
            );
            setIscapturing(false);
            if (isSucces) {
                setIsLoading(false);
                setSuccessMessage(true);
                setErrorMessage(false);
                await wait(5000);
                setAlreadyCaptured(true);
                setSuccessMessage(false);
                setSucces(false);
                dispatch(closePopUp());
            } else {
                setIsLoading(false);
                setSuccessMessage(false);
                setSucces(false);
                setErrorMessage(true);
                await wait(5000);
                setAlreadyCaptured(true);
                dispatch(closePopUp());
            }
            setName("");
            setLastName("");
            setEmail("");
            setOrderId("");
        }
    }, [dispatch, email, name, orderId, lastName]);

    useEffect(() => {
        if (success) onApprove();
    }, [success, onApprove]);

    useEffect(() => {
        isCheckedRef.current = isChecked;
    }, [isChecked]);

    async function closePopUpWithError() {
        setIsLoading(false);
        setSuccessMessage(false);
        setSucces(false);
        setErrorMessage(true);
        await wait(5000);
        dispatch(closePopUp());
    }

    async function createOrder(): Promise<string> {
        setIsLoading(true);
        let selectedProductId = popUpState.selectedProductId;
        const includeTranslation = isCheckedRef.current;

        try {
            if (selectedProductId !== null && selectedProductId !== "") {
                const orderId = await PaypalControler.getOrderId(selectedProductId, includeTranslation);
                setOrderId(orderId);
                setIsLoading(false);

                return orderId;
            }
        } catch (error) {
            console.error("Error al crear la orden:", error);
            closePopUpWithError();
        }
        return "";
    }

    const onErrorSetErrorMessage = (e: any) => {
        setErrorMessage(e);
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    if (isCapturing) {
        return <LoaderBlock />;
    }

    if (successMessage) {
        return (
            <div className={styles.messagesContainer}>
                <img className="w-[250px] rounded-lg" src={ResumatioPopUpLogo} alt="Resumatio Logo" />
                <p className={styles.successMessage}>
                    <span>Gracias por su compra, recibirá un correo en su casilla de emails.</span>
                    <CheckCircleOutlined className={styles.succesIcon} />
                </p>
            </div>
        );
    }

    if (errorMessage) {
        return (
            <div className={styles.messagesContainer}>
                <img className="w-[250px] rounded-lg" src={ResumatioPopUpLogo} alt="Resumatio Logo" />
                <p className={styles.errorMessage}>
                    <span>Ha ocurrido un error, intentélo de nuevo más tarde.</span>
                    <CloseCircleOutlined className={styles.succesIcon} />
                </p>
            </div>
        );
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.inputsContainer}>
                <img className="w-[250px] rounded-lg mx-auto" src={ResumatioPopUpLogo} alt="Resumatio Logo" />
                <RmInput
                    id="nameInput"
                    title="Nombre"
                    placeHolder="Nombre"
                    value={name}
                    onChangeCallback={(e) => setName(e.target.value)}
                />
                <RmInput
                    id="lastNameInput"
                    title="Apellido"
                    placeHolder="Apellido"
                    value={lastName}
                    onChangeCallback={(e) => setLastName(e.target.value)}
                />
                <RmInput
                    id="emailInput"
                    title="Email"
                    placeHolder="Email"
                    value={email}
                    onChangeCallback={(e) => setEmail(e.target.value)}
                />
                <div className={styles.falseInput}>
                    <div>
                        <span>Incluir traduccion </span>
                        <span className={styles.usdPrice}>{`(USD ${translationPrice})`}</span>
                    </div>
                    <input
                        className={styles.inputCheck}
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        onClick={handleCheckboxChange}
                    />
                </div>
            </div>

            <div className={styles.paypalContainer}>
                <PayPalButtons
                    disabled={!paypalEnlabed}
                    onError={(e) => {
                        onErrorSetErrorMessage(e.message);
                    }}
                    createOrder={async (data, actions) => await createOrder()}
                    onApprove={async (data, actions) => {
                        setOrderId(data.orderID);
                        setSucces(true);
                    }}
                />
            </div>
            {isLoading && <LoaderBlock smallSize />}
        </div>
    );
}
