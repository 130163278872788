import iconoContacto from "../../assets/images/generalInfoImages/Ícono_contacto.png";
import iconoServicios from "../../assets/images/generalInfoImages/Ícono_servicios.png";
import iconoElaboracion from "../../assets/images/generalInfoImages/Ícono_elaboración.png";
import iconoRevision from "../../assets/images/generalInfoImages/Ícono_revisión.png";
import iconoEntrega from "../../assets/images/generalInfoImages/Ícono_entrega.png";

export class GeneralInfoData {
	static data: GeneralInfoDataModel[] = [
		{
			image: iconoContacto,
			title: "Contacto inicial",
			subTitle: "Formulario de contacto - 24hs para tu respuesta",
		},
		{
			image: iconoServicios,
			title: "Servicios",
			subTitle: "Elija entre uno de nuestros tres servicios",
		},
		{
			image: iconoElaboracion,
			title: "Elaboración",
			subTitle: "Confección del servicio ajustado a tu medida",
		},
		{
			image: iconoRevision,
			title: "Revision y ajuste",
			subTitle: "Entrega del borrador - Opinión del cliente",
		},
		{
			image: iconoEntrega,
			title: "Entrega final",
			subTitle: "Entrega en multiformatos (PDF y Docs)",
		},
	];
}

export interface GeneralInfoDataModel {
	image: string;
	title: string;
	subTitle: string;
}
