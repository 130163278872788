import ResumatioPopUpLogo from "../../../../assets/images/ResumatioPopUpLogo.png";

function mobilePopUpView() {
	return (
		<div className="flex flex-col items-center justify-between gap-6">
			<img
				className="w-[250px] rounded-lg"
				src={ResumatioPopUpLogo}
				alt="Resumatio Logo"
			/>
			<div className="flex flex-col my-8 text-center gap-6 items-center">
				<h2 className="text-2xl font-semibold">
					Actualmente estamos trabajando en nuestra versión para dispositivos
					móviles
				</h2>
				<p className="text-lg">Muchas gracias por su comprensión</p>
			</div>
		</div>
	);
}
export default mobilePopUpView;
